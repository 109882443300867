<template>
  <v-container text-center>
    <v-layout row justify-center>
      <v-icon class="sucess_icon my-5" color="success">check_circle</v-icon>
    </v-layout>
    <v-layout row justify-center>
      <h3>
        Congratulations. You’re bank account is connected and it's ready to get
        paid!
      </h3>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>
<style scoped>
.sucess_icon {
  font-size: 50px;
}
</style>
